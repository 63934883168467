<template>

  <v-dialog
    v-model="show"
    persistent
    >
    <v-card>
       <v-toolbar dense dark color="secondary">
           Preview
       </v-toolbar>
        <v-card-text class="pa-4">
             GAME: {{ticket.game=="2DLOCAL"?"2D LIVE":ticket.game}}
            <br/>Draw: {{ticket.draw_date}}
            <br/>
            <table class="va-table print_table3 mt-2  text-left" cellspacing="0">
                      <tr v-for="(item, i) in lines" :key="i">
                          <td> {{item.num1}} - {{item.num2}}</td>
                          <!-- <td class="text-center">{{item.line_type}}</td> -->
                          <td class="text-right">{{$money(item.line_amount)}}</td>
                      </tr>
                  </table>
                   <v-row no-gutters class="text-primary">
                    <p class="sm-font text-info">BALANCE: {{$money(TOTAL)}}</p>
                    <v-spacer/>
                    <h4>TOTAL:  {{$money(TOTAL)}}</h4>
                  </v-row>
        </v-card-text>
        <v-card-actions>
                <v-btn color="warning"  @click="emitToParent('cancel')">CANCEL</v-btn>
            <v-spacer></v-spacer>
            <v-btn   color="secondary" @click="buyTicket()">Buy Now</v-btn>
        </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
    data: () => ({
    }),
  props: {
    show: {
        type: Boolean,
        default: false
    },
    lines: {
        type: Array,
         default: ()=> { return [] }
    },
    ticket: {
        type: Object,
        default: ()=> { return {} }
    }
  },
  watch:{
    // show(v){
    //   if(v) {
    //     this.clear()
    //   }
    // }
  },
  computed: {
    TOTAL() {
           return this.lines.reduce((res,item)=>res += this.$IsNum(item.line_amount), 0)
      }
  },
  methods: {
    emitToParent (action) {
      this.$emit('DialogEvent', action)
    },
    clear() {
        this.ticket = {}
        this.lines = []
    },
    buyTicket(){
        this.ticket.Lines = this.lines
         this.$http.post("ticket/new", this.ticket).then(response => {
                console.log(response.data)
                this.$emit('DialogEvent', response.data)
               }).catch(e => {
                console.log(e.data)
            })
    }
  }
}
</script>
