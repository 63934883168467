<template>

  <v-dialog
    v-model="show"
    persistent
    width="450"
    >
    <v-card tile >
      <v-toolbar dense flat><v-icon left @click="emitToParent()">mdi-arrow-left</v-icon> Load Tranfer</v-toolbar>
      <v-row no-gutters class="pa-2 mt-5">
        <v-col cols="12" v-if="auth==false" tile>
          <v-form autocomplete="off">
                <v-text-field
                  v-model="accountID"
                  label="To account. no."
                  :append-icon="'mdi-send'"
                  @click:append="GET_ACCOUNT_INFO(accountID)"
                  outlined
                ></v-text-field>
                <template v-if="IS_ACCOUNT">
                    <v-divider class="ma-1"/>
                    Account Information:
                      <div class="pa-2">
                        Acct. Name: {{ $store.state.account.fullname}}<br/>
                        Acct. No: {{ $store.state.account.id}} 
                      </div>  
                      <v-divider class="ma-1"/>
               
                  <v-text-field
                    v-model="text"
                    class="lg-font mt-4"
                    style="font-size: 20px; text-align: center;"
                    type="number"
                    label="Enter Load"
                    hide-details
                    outlined
                    large
                  />
                   </template>
          </v-form>

          <v-divider/>
        </v-col>
          <v-col cols="12" v-else>
                  <div class="lg-font mb-3 text-center"> Send  <span class="text-h5 text-success">{{$money(text)}}</span> to {{$store.state.account.fullname}}</div>
            <v-divider/>
          </v-col>
          <template v-if="text">
              <v-col cols="5" class="mt-5 pa-1">
                    <v-btn block    @click="text ='close', emitToParent()">Cancel</v-btn>
              </v-col>
              <v-col cols="7" class="mt-5  pa-1">
                <v-btn   block color="success" :loading="loading" @click="validateAmount()" v-if="auth==true">
                  <v-icon left   v-if="sendbtn=='SEND' || sendbtn=='Points sent'">mdi-{{icon}}</v-icon> 
                    
                    <template v-if="sendbtn=='SEND'">
                      Confirm and
                    </template>
                  {{sendbtn}}</v-btn>
                <v-btn   block color="success"  @click="ValidateSender()" v-else>
                  <v-icon left   v-if="sendbtn=='SEND' || sendbtn=='Points sent'">mdi-{{icon}}</v-icon> Transfer</v-btn>
            </v-col>
          </template>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  mapMutations
} from 'vuex'
export default {
    data: () => ({
        text: '',
        accountID: null,
        sendbtn:"SEND",
         interval: {},
         loading: false,
         value:0,
         auth: false,
    }),
  props: {
    show: {
        type: Boolean,
        default: false
    }, 
    toaccount: {
      type: Number,
      default: 0
    }
  },
  watch:{
      show(val) {
          if(!val){
            this.text=''
            this.sendbtn = "Transfer Load"
             this.auth=false
             this.accountID = null
             this.loading = false
             this.setAccount({})
        } else {
          this.accountID = this.toaccount
        }
      }
  },
  computed:{
      icon() {
         switch(this.sendbtn) {
           case "SEND":
             return "database-arrow-right"
          case "Points sent":
             return "check"
          default: 
            return ""
         }
      },
      IS_ACCOUNT(){
        if(this.$store.state.account.id){
          return true
        }
        return false
      }
  },
  methods: {
    ...mapMutations(['setAccount']),
    ValidateSender(){
         if(this.$IsNum(this.text.trim())) {
            this.auth = true
      } else {
        this.VA_ALERT('error', "Enter points!")
      }
    },
    validateAmount(){
      if(this.$IsNum(this.text.trim())) {
              this.sendbtn = "Cancel sending"
              this.loading = true
              this.Send()
             
      } else {
        this.VA_ALERT('error', "Enter points!")
      }
    },
    Send() {
        var param = {}
        param.type = "Transfer Load"
        param.description = "Transfer Load"
        param.amount = this.$IsNum(this.text.trim())
        param.account = this.$IsNum(this.accountID)
       console.log(param)
        this.$http.post("wallet/load",param).then(response => {
              if(response.data.status) {
                  this.VA_ALERT('success', "Points sent!")
                  
                  this.emitToParent()
              } else {
                  this.VA_ALERT('error', response.data.message)
              }
              this.loading = false
          }).catch(e => {
            console.log(e.data)
          })

    },

    emitToParent () {
      this.$emit('DialogEvent', this.text.trim())
    }
  }
}
</script>
