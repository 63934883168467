<template>
    <div v-if="show">
   
    <v-card dense>
        <v-toolbar>
           Daily Summary
            <v-spacer/>
               <span style="width: 200px; margin-top: 24px;"> 
                     <v-menu
                        v-model="from_menu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field 
                                label="From Date"
                                 :value="from_date"
                                 filled
                                 readonly
                                dense
                                outlined
                                append-icon="mdi-calendar"
                                v-on="on"
                            />
                        </template>
                        <v-date-picker v-model="from_date" no-title scrollable @input="from_menu=false" />
                    </v-menu>
               </span>
                <span style="width: 200px; margin-top: 24px;" class="ml-2"> 
                     <v-menu
                        v-model="to_menu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field 
                                label="To Date"
                                 :value="to_date"
                                 filled
                                 readonly
                                dense
                                outlined
                                append-icon="mdi-calendar"
                                v-on="on"
                            />
                        </template>
                        <v-date-picker v-model="to_date" no-title scrollable @input="to_menu=false, getTransactions()" />
                    </v-menu>
               </span>
               <v-icon right color="primary" @click="dlToCsv()">mdi-download</v-icon>
        </v-toolbar>
        <v-card-text>
             <v-progress-linear
                v-if="loading"
                indeterminate
                color="teal"
                ></v-progress-linear>
             <p>
Date Covered: {{$moment(from_date).format('YYYY-MMM-DD')}} - {{$moment(to_date).format('YYYY-MMM-DD')}} 
            </p>
            <table class="print_table" id="over-table">
                <tr>
                    <!-- <th>DRAW ID</th> -->
                    <!-- <th>GAME</th> -->
                    <!-- <th>STATUS</th> -->
                    <th class="text-left">DRAW DATE</th>
                    <th class="text-right">GROSS</th>
                    <!-- <th class="text-right">UNIT HITS</th>
                    <th class="text-right">AMOUNT HITS</th> -->
                </tr>
                 <tr v-for="(item,index) in items" :key="index" >
                     <!-- <td class="border_bot">{{item.draw_id}} </td> -->
                    <td class="border_bot">{{item.draw_date}} </td>
                      <td class="text-right border_bot">{{$money(item.gross)}}</td>
                </tr>
                <tr>
                    
                     <th  class="text-right" style="background: #efefef;">TOTAL:</th>
                    <th class="text-right"  style="background: #efefef;">{{$money(TOTALGROSS)}}</th>
                  
                </tr>
               
            </table>
        </v-card-text>     
    </v-card>
  </div>
</template>

<script>

export default {
  props: {
      show:{
          type: Boolean,
          default: false
      }
  },
  data: () => ({
        loading: false,
        from_menu: false,
        to_menu: false,
        from_date: '',
        to_date: '',
        items:[]
       
    }),
    computed:{
        TOTALGROSS() {
            var total = this.items.reduce((res,item)=>res += this.$IsNum(item.gross),0)
            return total
        },
        //  TOTALHITS() {
        //     var total = this.items.reduce((res,item)=>res += this.$IsNum(item.amount_of_winning),0)
        //     return total
        // },
        //  TOTALPAYOUT() {
        //     var total = this.items.reduce((res,item)=>res += this.$IsNum(item.payout),0)
        //     return total
        // }
    },
  methods: {
        getTransactions(){
            this.loading = true
            this.items =[]
            var param = {}
            param.from_date = this.from_date
            param.to_date = this.to_date
            param.method = "daily_gen_coordinator_summary"
            console.log(param)
             this.$http.post("report/transactions",param).then(response => {
                response.data.items !=null?this.items = response.data.items:this.items =[]
               // console.log(response.data)
                this.loading = false
          }).catch(e => {
            console.log(e.data)
          })
        },
        dlToCsv(){
            this.DownloadToCsv("over-table", 'Per-draw-' + this.from_date + '-to-' + this.to_date)
        }
  }
}
</script>
