<template>

  <v-dialog
    v-model="show"
    persistent
    >
    <v-card>
      <v-toolbar dense dark color="success">
          Your Ticket
       </v-toolbar>
        <v-card-text class="pa-4">
            GAME: {{ticket.game=="2DLOCAL"?"2D LIVE":ticket.game}}
            <br/>DRAW: {{ticket.draw_date}}
            <br/>TICKET #: {{ticket.id}}
            <template v-if="ticket.code"> <br/>TICKET CODE: {{ticket.code}}</template>
             <br/>TICKET DATE: {{$moment(ticket.transaction_date).format("MMM DD, YYYY hh:mm:ss a")}}
            <br/>
            <table class="va-table print_table3 mt-2 text-left" cellspacing="0">
                      <tr v-for="(item, i) in ticket.ResponseLines" :key="i">
                          <td> {{item.num1}} - {{item.num2}}</td>
                          <!-- <td class="text-center">{{item.line_type}}</td> -->
                          <td class="text-right">{{$money(item.line_amount)}}</td>
                      </tr>
                  </table>
                   <v-row no-gutters class="text-primary">
                    <p class="sm-font text-info" v-if="ticket.code">BALANCE: {{$money(ticket.amount)}}</p>
                    <v-spacer/>
                    <h4>TOTAL:  {{$money(ticket.amount)}}</h4>
                  </v-row>
                   <v-row no-gutters class="text-primary">
                   </v-row>
        </v-card-text>
        <v-card-actions>
                <v-btn color="success" block  @click="emitToParent('done')">Done</v-btn>
        </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
    data: () => ({
    }),
  props: {
    show: {
        type: Boolean,
        default: false
    },
    ticket: {
        type: Object,
        default: ()=> { return {} }
    }
  },
  watch:{
    // show(v){
    //   if(v) {
    //     this.clear()
    //   }
    // }
  },
  computed: {
  },
  methods: {
    emitToParent (action) {
      this.$emit('DialogEvent', action)
    },
    clear() {
        this.ticket = {}
        this.lines = []
    }
  }
}
</script>
