<template>
 <v-dialog
    v-model="show"
    persistent
    >
        <v-row dense>
            <v-col cols="12" class="">
             <v-card class="ma-2">
              <v-toolbar dense flat class="pa-1">
                 Current Tickets
           
                 <v-spacer/>
                <v-icon @click="emitToParent('cancel')">mdi-close</v-icon>
              </v-toolbar>
                  <table class="va-table print_table3 mt-1 md-font text-left" cellspacing="0">
                     <tr>
                       <th  style="background: #eaeaea; padding: 4px">TICKET</th>
                       <th  style="background: #eaeaea; padding: 4px" class="text-right">AMOUNT</th>
                     </tr>
                      <tr v-for="(item, i) in tickets" :key="i">
                          <td @click="ticket = item, viewticket = true"> {{item.id}}
                          <br/>{{item.draw_date}}
                          </td>
                          <td class="text-right" @click="ticket = item, ticketviewticket = true">{{$money(item.amount)}}</td>
                      </tr>
                  </table>
             </v-card>
            </v-col>
        </v-row>
         
<va-ticket :show="viewticket" :ticket="ticket" @DialogEvent="ticketEvent"/>
 </v-dialog>
</template>
<script>
import {
  mapMutations
} from 'vuex'
  export default {
    props: {
      show: {
        type: Boolean,
        default: false
      },
       tickets: {
        type: Array,
        default: ()=>[]
      }
    },
    data: () => ({
      ticket: {},
      to_menu: false,
      viewticket: false,
      to_date:'',
      confirm: false,
      cancelbtn: 'No',
      okbtn: "Ok",
      confirmText: "",
      action:''
    }),
    // created() {
    //   this.PING()
    // },
    //  mounted(){
    //    this.setLoggedIn(true)
    //     if (this.user == null) {
    //       this.$router.push('/')
    //     }else {
    //       this.$http.defaults.headers.common['Authorization'] = 'Bearer ' + this.user.token
    //       this.to_date = this.$moment().format("YYYY-MM-DD")
    //       this.setData()
    //     }
    // },
    computed:{
      user() {
        if(localStorage.user){
            return JSON.parse(localStorage.user)
        }
        return null
      },
      TOTAL(){
        var total =  this.ITEMS.reduce((res, item)=>res += this.$IsNum(item.amount),0)
        return total
      },
      ITEMS() {
         return this.$store.state.tickets
      },
      isOnline(){
        return this.$store.state.is_online
      }
    },
    methods: {
      ...mapMutations(['setAlert', 'setAlertColor', 'setAlertText', 'setLoggedIn']),
      emitToParent(){
        this.$emit('DialogEvent', "")
      },
      ticketEvent(){
        this.viewticket =false
      },
      setData() {
        var param = {}
        param.account = this.user.id
        param.method = "per_account"
        param.from_date = this.to_date
        this.GET_TICKETS(param)
      }
    },
  }
</script>