<template>

  <v-dialog
    v-model="show"
    persistent
    fullscreen
    >
    <v-card>
       <v-toolbar  dark color="secondary">
          <v-spacer/> <div class="text-h4"> {{num1}} - {{num2}}</div> <v-spacer/> 
         <v-icon @click="clear()"  v-if="ShowClear">mdi-close</v-icon>
       </v-toolbar>
      
        <v-card-text class="text-center mt-4">
           <v-btn fab small color="white" class="lg-font ma-1" v-for="num in 38" :key="num"  @click="setNum(num)" >{{num}}</v-btn>
        </v-card-text>
        <v-card-actions>
                <v-btn color="warning"  @click="emitToParent('cancel')">CANCEL</v-btn>
            <v-spacer></v-spacer>
            <v-btn   color="success"    @click="genComb()">LUCKY PICK</v-btn>
             <v-spacer></v-spacer>
            <v-btn   color="secondary"    @click="bet_type='RBL', validateNums()">Proceed</v-btn>
        </v-card-actions>
    </v-card>
    <va-amount-form :show="amountDialog" :linetype="bet_type" @DialogEvent="amountEvent"/>
  </v-dialog>
</template>

<script>
export default {
    data: () => ({
      num1: '',
      num2: '',
      amountDialog: false,
      line: {},
      bet_type:'STD'
    }),
  props: {
    show: {
        type: Boolean,
        default: false
    }
  },
  watch:{
    show(v){
      if(v) {
        this.clear()
      }
    }
  },
  computed: {
    ShowClear() {
      if(this.num1 || this.num2) {
        return true
      } 
      return false
    }
  },
  methods: {
    genComb() {
       this.num1 = this.getRand(1,38)
        this.num2 =this.getRand(1,38)
    },
    getRand(min, max){
          min = Math.ceil(min);
          max = Math.floor(max);
          return Math.floor(Math.random() * (max - min + 1)) + min;
    },
    amountEvent(e){
      this.amountDialog = false
      if(e != 'close') {
        this.returnLine(e)
      }
    },
    emitToParent (action) {
      this.$emit('DialogEvent', action)
    },
    clear() {
      this.num1 = ''
        this.num2 = ''
        this.line = {}
    },
    returnLine(amount){
      this.line.line_amount = amount
      this.line.total_amount =  this.line.line_amount
      this.line.line_type = this.bet_type
      this.line.num1 = this.num1
      this.line.num2 = this.num2
      this.emitToParent(this.line)
    },
    validateNums(){
     if(this.num1 && this.num2) {
       this.amountDialog = true
      } else {
          this.VA_ALERT('error', "Complete number combination!")
      }
    },
    setNum(val) {
      if(this.num1 == '') {
          this.num1 = val
      } else {
         if(this.num2 == '') {
            this.num2 = val
         }
      }
    }
  }
}
</script>
