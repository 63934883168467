<template>

  <v-dialog
    v-model="show"
    persistent
    max-width="300">
    <v-card color="secondary" dark>
        <v-card-text class="pa-4 subtitle-1">
          <v-form
                ref="form"
                v-model="valid"
                lazy-validation
              >
                  <v-text-field
                    v-model="pass1"
                    @focus="pass1=''"
                    label="Enter new password"
                    :rules="textRules"
                    required
                     type="password"
                    outlined
                    dense
                  />
                    <v-text-field
                    v-model="pass2"
                    @focus="pass2=''"
                    label="Re-type Password"
                    :rules="textRules"
                    required
                    type="password"
                    outlined
                    dense
                  />
          </v-form>
        </v-card-text>
        <v-card-actions>
                <v-btn color="warning" small text rounded  @click="text ='close', emitToParent()">Cancel</v-btn>
            <v-spacer></v-spacer>
            <v-btn  small color="success"  rounded  @click="validate()">Change</v-btn>
        </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
    data: () => ({
        discount: {},
        pass1:"",
        pass2:"",
        valid: true,
        textRules: [
        v => !!v || 'Field is required'
      ],
    }),
  props: {
    show: {
        type: Boolean,
        default: false
    }
  },
  watch:{
      show(val) {
          if(!val){
            this.pass1=""
            this.pass2=""
            }
      }
  },
   computed:{
      user() {
        if(localStorage.user){
            return JSON.parse(localStorage.user)
        }
        return null
      }
    },
  methods: {
    emitToParent () {
      this.$emit('DialogEvent', "close")
    },
    validate () {
      if (this.$refs.form.validate()) {
          if(this.pass1 == this.pass2) {
           this.ChangePassword()
          } else {
            this.VA_ALERT("error", "Password not match!")
          }
      }
    },
    ChangePassword(){
      var param = {}
      param.id= this.user.id
      param.password = this.pass1
      this.CHANGE_PASSWORD(param)
      this.$emit('DialogEvent', "close")
    }
  }
}
</script>
