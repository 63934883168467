<template>

  <v-dialog
    v-model="show"
    persistent
    fullscreen>
    <v-card >
      <v-toolbar dense  color="#222222" dark>
           <v-icon color="warning" @click="text ='close', emitToParent('close')" left>mdi-arrow-left</v-icon> {{state=='new'?'REGISTER':'UPDATE'}} ACCOUNT
          <v-spacer/>
         
      </v-toolbar>
        <v-card-text class="pa-4 subtitle-1">
            <v-form
                ref="form"
                v-model="valid"
                autocomplete="off"
                lazy-validation>
               <span class="text-info"><v-icon color="warning" left>mdi-account-cog-outline</v-icon> Account Information</span>
                <v-divider  class="mb-5"/>

                  <div class="text-center mb-4" v-if="state!='new'">
                      <div class="text-h4"> <v-icon left large color="gold">mdi-database</v-icon>{{$money(user.balance)}}</div>
                      <small class="text-warning">Current Points</small>
                  </div>
                <v-autocomplete
                   v-model="user.role"
                    label="Account Type"
                    :items="ROLES"
                    dense
                    outlined
                    :rules="nameRules"
                    required
                />
                <v-text-field 
                    v-model.trim="user.fullname"
                    label="Fullname"
                    dense
                    outlined
                    :rules="nameRules"
                    required
                />
                <v-text-field 
                    v-model.trim="user.email"
                    label="Email"
                    dense
                    outlined
                />
                <v-text-field 
                    v-model.trim="user.contact_no"
                    label="Mobile No."
                    required
                     :rules="nameRules"
                    dense
                    outlined
                />
                <v-text-field 
                    v-if="user.role != 'Player'"
                    v-model.trim="user.commission"
                    label="Commission %"
                    required
                     :rules="nameRules"
                    dense
                    outlined
                />
                  <v-autocomplete
                   v-model="user.status"
                    label="Account Status"
                    :items="['Active', 'Deactivated']"
                    dense
                    outlined
                    :rules="nameRules"
                    required
                />
                <span class="text-info"><v-icon color="warning" left>mdi-key-chain-variant</v-icon> Login Credentials</span>
                <v-divider  class="mb-5"/>
                <v-text-field 
                    v-model="user.username"
                    label="Login Username"
                    dense
                    outlined
                    :rules="nameRules"
                    required
                />
                <v-text-field 
                    v-if="state=='new'"
                    v-model="user.password"
                    label="Login Password"
                    dense
                    outlined
                    :rules="nameRules"
                    required
                />
                <!-- <template v-else>
                    *** <v-icon right color="warning" small @click="sendform=true">mdi-pencil</v-icon>
                </template> -->
            </v-form>
              <v-divider  class="mb-5"/>
        </v-card-text>
        
        <v-card-actions>
              <!-- <v-btn color="accent" v-if="state!='new'" @click="sendform=true">SEND POINTS</v-btn>
               <v-btn color="info" v-if="state!='new'" @click="cashform=true">Cash out</v-btn> -->
               <v-btn color="warning"   @click="emitToParent('close')">Close</v-btn>
                 <v-spacer/>
            <v-btn color="success"   @click="validate()">{{state=='new'?'REGISTER':'Save'}}</v-btn>
        </v-card-actions>
    </v-card>
    <!-- <va-password-form :show="sendform" @DialogEvent="pasEvent"/> -->
    <va-send-form :show="sendform" :account="user" @DialogEvent="pasEvent"/>
    <va-cashout-form :show="cashform" :account="user" @DialogEvent="pasEvent"/>
  </v-dialog>
</template>

<script>
export default {
    data: () => ({
        text: '',
        valid: true,
        cashform: false,
        password: '',
        sendform: false,
        user:{},
        loggeduser: {},
         nameRules: [
            v => !!v || '*Required'
        ],
    }),
  props: {
    show: {
        type: Boolean,
        default: false
    },
    state: {
      type: String,
      default: 'new'
    },
    data: {
      type: Object,
      default: () => {}
    }
  },
  watch:{
      show(val) {
          if(val){
            this.state == 'new' ? this.user={}:this.user=this.data
              if(this.state != 'new') {
                this.userDetails()
              }
              this.loggeduser = JSON.parse(localStorage.user)
              console.log(this.loggeduser)
         }
      }
  },
  computed: {
    ROLES() {
     switch(localStorage.user_role) {
          case "General Coordinator":
            return ['Player', 'Agent','Coordinator']
        case "Agent":
          return ["Sub-Agent","Sub-Agent2", "Player"]
        case "Sub-Agent":
          return ["Sub-Agent2", "Player"]
        default: 
          return ["Player"]
      }
    }
  },
  methods: {
    userDetails(){
          this.$http.post("user/details",{id: this.user.id}).then(response => {
              if(response.data.status) {
                 this.user = response.data.account
              } 
          }).catch(e => {
            console.log(e.data)
          })
    },
    pasEvent(){
        this.sendform = false
        this.cashform = false
        this.userDetails()
      },
     validate () {
      if (this.$refs.form.validate()) {
         console.log(this.user)
         this.Save()
       }
      },
    emitToParent (action) {
      this.$emit('DialogEvent', {action: action, text:this.text.trim()})
    }, 
    Save(){
          var method = "user/register"
          this.user.commission =  this.$IsNum(this.user.commission)
          if(this.$IsNum(this.loggeduser.commission) <  this.user.commission) {
            this.VA_ALERT("error", "Commission too high!")
            return 
          }
          this.user.coordinator = this.loggeduser.id
          this.user.area = this.loggeduser.area
          this.$http.post(method, this.user).then(response => {
              response.data.status?this.VA_ALERT('success',response.data.message):this.VA_ALERT('error',response.data.message)
          this.$emit('DialogEvent', 'close')
          }).catch(e => {
              console.log(e)
          })
      }
  }
}
</script>
