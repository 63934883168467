<template>
    <div v-if="show">
   
    <v-card dense>
        <v-toolbar>
           PER GAME ACCOUNT SUMMARY
            <v-spacer/>
               <!-- <span style="width: 200px; margin-top: 24px;"> 
                     <v-menu
                        v-model="from_menu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field 
                                label="From Date"
                                 :value="from_date"
                                 filled
                                 readonly
                                dense
                                outlined
                                append-icon="mdi-calendar"
                                v-on="on"
                            />
                        </template>
                        <v-date-picker v-model="from_date" no-title scrollable @input="from_menu=false" />
                    </v-menu>
               </span> -->
                <span style="width: 200px; margin-top: 24px;" class="ml-2"> 
                     <v-menu
                        v-model="to_menu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field 
                                label="To Date"
                                 :value="to_date"
                                 readonly
                                 filled
                                dense
                                outlined
                                append-icon="mdi-calendar"
                                v-on="on"
                            />
                        </template>
                        <v-date-picker v-model="to_date" no-title scrollable @input="to_menu=false, getTransactions()" />
                    </v-menu>
               </span>
               <v-icon right color="primary" @click="dlToCsv()">mdi-download</v-icon>
        </v-toolbar>
        <v-card-text>
             <v-progress-linear
                v-if="loading"
                indeterminate
                color="teal"
                ></v-progress-linear>
            <p class="text-center">
               PER GAME ACCOUNT SUMMARY<br />
Draw Period: OVERALL<br />
Date Covered: {{$moment(from_date).format('YYYY-MMM-DD')}} - {{$moment(to_date).format('YYYY-MMM-DD')}} 
            </p>
            <div style="width: 100%; overflow: scroll">
            <table class="print_table" id="over-table" >
                
                <tr>
                    <!-- <td>DATE</td> -->
                    <!-- <td>CLUSTER</td> -->
                    <!-- <td>BARANGAY</td>
                    <td>RIDER</td> -->
                    <!-- <td>TYPE</td> -->
                    <!-- <td>GEN. COOR</td> -->
                    <!-- <td>AGENT</td> -->
                    <!-- <td>COMM.(%)</td> -->
                    <td style="background: #dedede">USERNAME</td>
                    <td class="text-right" style="background: #dedede">LAST TWO</td>
                    <td class="text-right" style="background: #dedede">3D</td>
                    <td class="text-right" style="background: #dedede">4D</td>
                    <td class="text-right" style="background: #dedede">PICK 3</td>
                <!-- <td class="text-right">NET GROSS</td> -->
                </tr>
              <template  v-for="(coor, coor_index) in PER_COOR_ACCOUNTS" >
                   <tr :key="coor_index+'-coor-name'">
                          <td colspan="5" style="background: #5CD0CC">{{coor.coor_name}}</td>
                     </tr>   
                    <tr v-for="(account, index) in coor.accounts" :key="index+'-account-'+coor_index">
                  
                   <td style="padding-left: 15px;"> -- {{account.agent}}</td>
                    <template v-for="(summary,i) in get_summary(account.account_id, account.date)">
                       <template v-if="$IsNum(summary.gross) > 0 ">
                            <td class="text-right" :key="account.account_id+'-g'+i" style="background: #dedede">{{$money($IsNum(summary.gross))}} </td>
                         
                            <!-- <td class="text-right" style="background: #B8D0CF" :key="account.account_id+'-h'+i">{{$money($IsNum(summary.hits_amount))}}</td> -->
                       </template>
                          <template v-else>
                            <td class="text-right" :key="i + '-1f'" style="background: #dedede">--</td>
                            <!-- <td class="text-right" :key="i + '-4f'" style="background: #B8D0CF">--</td> -->
                       </template>
                    </template>
              
                  <!-- <template v-for="(data,o) in over_all_data(account)">
                    <td class="text-right" :key="o+'-o-g'" style="background: #F4EBC1">{{ $money(data)}}</td>
                  </template> -->
                </tr>
             
              </template>
             
            </table>
            </div>
        </v-card-text>     
    </v-card>
  </div>
</template>

<script>
//import template from '../../views/template.vue'

export default {
//  components: { template },
  props: {
      show:{
          type: Boolean,
          default: false
      }
  },
  data: () => ({
        loading: false,
        from_menu: false,
        to_menu: false,
        from_date: '',
        to_date: '',
        accounts:[],
        games:[]
       
    }),
    computed:{
        GAMESUMMARY() {
            var byAccounts = this.$groupBy('account')
            return byAccounts(this.games)
        },
        PER_COOR_ACCOUNTS() {
            var entries = []
             var bycoor = this.$groupBy('coor_name')

             for (const [key, value] of Object.entries(bycoor(this.accounts))) {
                    var item = {
                        coor_name: key,
                        accounts: value 
                    }
                   entries.push(item)
            }

            return entries
        }
    },
  methods: {
      convertSeq(str){
            return this.$IsNum(str.replace("draw ", "")) -1
      },
    //    account_total(agents){
    //          var  gross = agents.reduce((res,item)=>{
    //             res += this.$IsNum(item.gross)
    //             return res 
    //         }, 0)
    //   },
      over_all_data(account){
           var data = this.get_summary(account.account_id, account.date)
           var  gross = data.reduce((res,item)=>{
                res += this.$IsNum(item.gross)
                return res 
            }, 0)
              var  hits = data.reduce((res,item)=>{
                res += this.$IsNum(item.hits_amount)
                return res 
            }, 0)
            //  var  comm = data.reduce((res,item)=>{
            //     res += this.$IsNum(item.gross) * (this.$IsNum(account.commission)/100)
            //     return res 
            // }, 0)
            //  var  net = data.reduce((res,item)=>{
            //     res += this.$IsNum(item.gross) - (this.$IsNum(item.gross) * (this.$IsNum(account.commission)/100))
            //     return res 
            // }, 0)
            //  var  revenue = data.reduce((res,item)=>{
            //     res +=this.$IsNum(item.gross) - this.$IsNum(item.hits_amount)
            //     return res 
            // }, 0)
            return [gross,hits]
      },
      get_total(summary){
          return this.$IsNum(summary.gross) - this.$IsNum(summary.hits_amount)
      },
      get_summary(account, drawDate){
        console.log(drawDate)
        for (const [key, value] of Object.entries(this.GAMESUMMARY)) {
            if(key == account)
                return this.get_value(value, drawDate)
            }
       return []
      },
        get_value(value, drawDate){
            var sum = value.reduce((res,item)=>{
                  if(item.draw_date == drawDate){
                      res.push(item)
                  }  
                  return res
            },[])
            
           
             var L2 =  sum.reduce((res,item)=>{
                  if(item.game == 'LASTTWO'){
                      res = item
                  }  
                  return res
            },{game:'LASTTWO'})
             var D3 =  sum.reduce((res,item)=>{
                  if(item.game == '3D'){
                      res = item
                  }  
                  return res
            },{game:'3D'})

             var D4 =  sum.reduce((res,item)=>{
                  if(item.game == '4D'){
                      res = item
                  }  
                  return res
            },{game:'4D'})
             var P3 =  sum.reduce((res,item)=>{
                  if(item.game == 'PICK3'){
                      res = item
                  }  
                  return res
            },{game:'PICK2'})
            return [L2,D3,D4,P3]
        },
        getTransactions(){
            this.loading = true
            this.items =[]
            var param = {}
            param.from_date = this.to_date
            param.to_date = this.to_date
            param.Method = "per_game_gencoor_summary"
             this.$http.post("report/transactions",param).then(response => {
                response.data.games !=null?this.games = response.data.games:this.games =[]
                 response.data.accounts !=null?this.accounts = response.data.accounts:this.accounts =[]
                this.loading = false
                     console.log(this.games)
                console.log(this.accounts)
          }).catch(e => {
            console.log(e.data)
          })
        },
        dlToCsv(){
            this.DownloadToCsv("over-table", 'Game-account-summary-' + this.from_date + '-to-' + this.to_date)
        }
  }
}
</script>
