/**
 * Define all of your application routes here
 * for more information on routes, see the
 * official documentation https://router.vuejs.org/en/
 */
export default [
    {
      path: '/dashboard',
      name: 'Dashboard',
      view: 'Dashboard'
    },
    {
      path: '/login',
      name: 'Login',
      view: 'Login'
    },
    {
      path: '/play',
      name: 'Play RNG',
      view: 'Play'
    },
    {
      path: '/live',
      name: 'Play Live',
      view: 'Live'
    },
    {
      path: '/bookings',
      name: 'Bookings',
      view: 'Bookings'
    },
    {
      path: '/account',
      name: 'My Account',
      view: 'MyAccount'
    },
    {
      path: '/all-accounts/:type',
      name: 'Account',
      view: 'AllAccounts'
    },
    {
      path: '/view-accounts/:id',
      name: 'View Account',
      view: 'ViewAccount'
    },
    {
      path: '/results',
      name: 'Results',
      view: 'Results'
    },
    {
      path: '/sales',
      name: 'Ticket Sales',
      view: 'reports/Tickets'
    },
    {
      path: '/bets',
      name: 'Bets',
      view: 'reports/Bets'
    },
    {
      path: '/wallet',
      name: 'Transactions',
      view: 'reports/Wallet'
    },
    {
      path: '/claim',
      name: 'Claim Tickets',
      view: 'reports/Claim'
    },
    {
      path: '/reports',
      name: 'Report Summaries',
      view: 'reports/Summary'
    },
    {
      path: '/cashouts',
      name: 'Ccshouts',
      view: 'reports/Cashout'
    },
    {
      path: '/commissions',
      name: 'Commissions Logs',
      view: 'reports/Commissions'
    },
    {
      path: '/players',
      name: 'Agents and Players',
      view: 'Players'
    },
    {
      path: '/register/:id',
      name: 'Registration',
      view: 'Registration'
    }
    
  ]
  