<template>
     <v-card :class="color" dark>
              <v-card-text style="height: 130px;" class="text-center">
                {{label}}
                  <div class="text-gold text-shadow">
                      <v-btn color="#fff" fab x-large class="ma-1"><span class="text-h4" style="color: #212121;">{{NUM[0]}}</span></v-btn>
                      <v-btn fab color="#fff"  x-large class="ma-1"><span class="text-h4" style="color: #212121;">{{NUM[1]}}</span></v-btn> 
                   </div>
                </v-card-text>
            </v-card>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: "secondary"
    },
    icon: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: ''
    },
    data: {
      type: String,
      default: ''
    },
    sup: {
      type: String,
      default: ''
    }
  },
  computed:{
    NUM(){
        var n = this.data.split(' - ')
        return n
    }
  }
}
</script>