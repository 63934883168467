
export default {
    drawer: true, 
    responsive: false,
  
  //  systemlogo: 'file:///android_asset/www/logo.png',
  //   applogo: 'file:///android_asset/www/logo.png',
    systemlogo: '../logo.png',
    applogo: '../logo.png',
    is_logged: false,
    is_online: false,
    show_alert: false,
    overlay_loading: false,
    overlay_loading_text: '',
    alert_color: "secondary",
    alert_text: "No message",
    product_items:[],
    suppliers:[],
    results:{},
    trucks:[],
    users:[],
    tickets:[],
    fulls:[],
    empties:[],
    sales:[],
    last_update:'',
    dr_number:'',
    uploaded_date:'',
    dr_date:'',
    balance:{},
    customers:[],
    open_draws:[],
    watchdraw: false,
    open_machine: false,
    account:{},
    open_live_draws:[],
    games: ['All Games', '2D','3D', 'STL-SILAY', '2D-STL-ILOILO-CITY', '3D-STL-ILOILO-CITY', 'STL-ILOILO-PROVINCE'],
    live_draw_status:""
  }
  