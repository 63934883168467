import {
    mapMutations
  } from 'vuex'
export const API_MIXINS = {
    methods: {
        ...mapMutations([
            'setAlert',
            'setAlertColor', 
            'setAlertText', 
            'setLoggedIn', 
            'setSetOpenDraws',
            'setSetOpenLiveDraws',
            'setIsOnline',
            'setTrucks',
            'setTickets',
            'setCategories',
            'setResults',
            'setCustomertypes',
            'setBalance',
            'setUsers',
            'setAccount',
            'setOverlayLoading',
            'setOverlayLoadingText'
        ]),
        GAMENAME(name){
            return name
        },
        GET_ALL_ACCOUNTS(user_id,user_role){
            this.$http.post("account/get_players",{id: user_id, role: user_role}).then(response => {
                console.log(response.data)
               response.data.list != null?this.setUsers(response.data.list):this.setUsers([])
             }).catch(e => {
               console.log(e.data)
             })
         },
        SendToken(){
              var user =   JSON.parse(localStorage.user)
              if(localStorage.device_token_save == localStorage.device_token) {
                  console.log("token updated!")
                  return
              }
            this.$http.post("user/token_update", {id: user.id, token: localStorage.device_token}).then(response => {
               if(response.data.status) {
                localStorage.device_token_save = localStorage.device_token
               }
               }).catch(e => {
                console.log(e.data)
            })
        },
        CHANGE_PASSWORD(account) {
            this.$http.post("user/change_password", account).then(response => {
               this.GET_RESPONSE(response)
               }).catch(e => {
                console.log(e.data)
            }) 
        },
        ACTIVEGAMES(user_games){
            var games = []
            for(var c=0;c<user_games.length;c++) {
                if(user_games[c].comm ==1) {
                 games.push(user_games[c].game)
                }
            }
            return games
          },
          GET_ACCOUNT_INFO(_id){
           
            this.$http.post("user/details",{id: this.$IsNum(_id)}).then(response => {
             console.log(response.data)
             response.data.status?this.setAccount(response.data.account):this.setAccount({})
            }).catch(e => {
              console.log(e.data)
            })
      }, 
        GET_RESULTS(user_games) {
            this.$http.get("report/get_results").then(response => {
               
                // if(user_games.length == 0 || user_games == null) {
                //     this.setResults([])
                // }  else {
                //     console.log(response.data.results)
                //     var results =  response.data.results
                //     var games = []
                //     for(var i=0;i<results.length;i++) {
                //         for(var c=0;c<user_games.length;c++) {
                //             if(results[i].game == user_games[c].game &&  user_games[c].comm ==1) {
                                
                //                 games.push(results[i])
                //             }
                //         }
                //     }
                //     console.log("games", games)
                //     this.setResults(games)
                // }
                this.setResults( response.data.results)
                console.log( user_games,  response.data.results)
               }).catch(e => {
                console.log(e.data)
            }) 
        },
        CHECK_BALANCE(account) {
            this.$http.post("wallet/balance", {account:account}).then(response => {
                response.data.account != null ?this.setBalance(response.data.account): this.setBalance({})
               }).catch(e => {
                console.log(e.data)
            }) 
        },
        IS_ODD(num){
            if((this.$IsNum(num)%2) != 0) {
                    return 'odd'
            }
            return 'even'
        },
        GET_OPEN_GAMES(gamename){
            console.log("GET_OPEN_GAMES")
            this.$http.post("draw/get_open_draws", {game: gamename, status: "per_game"}).then(response => {
                if(gamename == "2D") {
                    response.data.draws != null ?this.setSetOpenDraws(response.data.draws): this.setSetOpenDraws([])
                } else {
                    response.data.draws != null ?this.setSetOpenLiveDraws(response.data.draws): this.setSetOpenLiveDraws([])
                }
                console.log(response.data.draws )
               }).catch(e => {
                console.log(e.data)
            })
        },
        UPDATE_RECORDS(userid){
            this.GET_CUSTOMERS(userid)
              setTimeout(()=>{this.GET_PRODUCTS()},700)
           },
        GET_CUSTOMERS(userid) {
            this.APPLOADER(true,"Updating customers records...")
            this.$http.post("mobile/get_customers",{salesman: userid}, {timeout: 120000}).then(response => {
                response.data.customers != null ?localStorage.customers = JSON.stringify(response.data.customers): delete localStorage.customers
                this.SET_CUSTOMERS()
                setTimeout(()=>{this.APPLOADER(false,"")},400)
            }).catch(e => {
                this.loading = false
                this.NETWORK_ERROR(e)
            })
        },
        GET_PRODUCTS() {
               this.APPLOADER(true,"Updating product records...")
               this.$http.post("mobile/get_products",{}, {timeout: 12000}).then(response => {
                   response.data.fulls != null?localStorage.fulls = JSON.stringify(response.data.fulls): delete localStorage.fulls
                   response.data.empties != null?localStorage.empties = JSON.stringify(response.data.empties): delete localStorage.empties
                   localStorage.last_update = this.$moment().format("DD-MMM-YY hh:mm A")
                   this.SET_FULLS()
                   this.SET_EMPTIES()
                   this.SET_LAST_UPDATE()
                   setTimeout(()=>{this.APPLOADER(false,"")},800)
               }).catch(e => {
                   this.loading = false
                   this.NETWORK_ERROR(e)
               })
       },
       STATUS_COLOR(status){
        switch(status) {
           case "Add Load":
             return "text-success"
           case "Cash Out":
             return "text-warning"
            case "Buy Ticket":
            return "text-info"
            case "Win":
                return "text-success"
           default:
             return ""
        }
     },
     statusIndicator(_time) {
        //var current = this.$moment(_time)
        var a = this.$moment()
          var b = this.$moment(_time)
         var diff = a.diff(b, 'hour')
         if(diff <=  3) {
            return 'success'
         } else if(diff > 3 && diff < 5) {
             return '#A5D6A7'
         } else {
           return '#989898'
         }
    },
        validateItems(items){
            var arrItems = items.reduce((res,item)=>{
                    item.sold = 0
                    res.push(item)
                    return res
            }, [])
            return arrItems
        },
        GET_TICKETS(param) {
            this.$http.post("transaction/query", param).then(response => {
                response.data.transactions !=null?this.setTickets(response.data.transactions):this.setTickets([])
               }).catch(e => {
                console.log(e.data)
            })
        },
        GET_RESPONSE(response){
            response.data.status?this.VA_ALERT('success', response.data.message):this.VA_ALERT('error', response.data.message)
        },
        VA_ALERT(color, message) {
                this.setAlertText(message)
                this.setAlertColor(color)
                this.setAlert(true)
        },
        APPLOADER(state, text=''){
            state?this.setOverlayLoadingText(text):this.setOverlayLoadingText('')
            this.setOverlayLoading(state)

        },
          NETWORK_ERROR(e){
            if(e.response == undefined) {
                    this.VA_ALERT('Error', "Server Unreachable!")
            } else {
                this.VA_ALERT('error', "No avaialable this time!")
            }
          },
          PING() {
            this.$http.post("ping", {}, {timeout: 2000}).then(() => {
                
                this.setIsOnline(true)
               }).catch(e => {
                this.setIsOnline(false)
                this.NETWORK_ERROR(e)
            })
        },
        CLEAR_SESSION() {
            delete localStorage.customers
            delete localStorage.user
            delete localStorage.device_token_save
        },
        DATA_SIZE(){
            var _lsTotal = 0,
            _xLen, _x;
            for (_x in localStorage) {
                if (!localStorage.hasOwnProperty(_x)) {
                    continue;
                }
                if(_x.substr(0, 50) == 'sales') {
                    _xLen = ((localStorage[_x].length + _x.length) * 2);
                    _lsTotal += _xLen;
                   // console.log(_x.substr(0, 50) + " = " + (_xLen / 1024).toFixed(2) + " KB")
                }
            }
            return (_lsTotal / 1024).toFixed(2)
        },
        VA_TIME(){
            //2020-05-12 01:24:51.272793+08
         return this.$moment().format() //2020-05-16 06:28:59
        }
        
    } ///eof methods
}