export const set = property => (state, payload) => (state[property] = payload)

export const toggle = property => state => (state[property] = !state[property])

export const save = property => (state, payload) => {
     state[property] = payload
     localStorage.product_items = JSON.stringify(payload)
  } 

export default {
  setDrawer: set('drawer'),
  setResponsive: set('responsive'),
  setLoggedIn: set('is_logged'),
  setAlert: set('show_alert'),
  setAlertColor: set('alert_color'),
  setOverlayLoading: set('overlay_loading'),
  setOverlayLoadingText: set('overlay_loading_text'),
  setAlertText: set('alert_text'),
  setTrucks: set('trucks'),
  setTickets: set('tickets'),
  setProducts: set('products'),
  setCustomers: set('customers'),
  setUsers: set('users'),
  setIsOnline: set('is_online'),
  setProductItems:  set("product_items"),
  setDrNumber: set("dr_number"),
  setDrDate: set("dr_date"),
  setResults:  set("results"),
  setEmpties: set("empties"),
  setLastUpdate: set("last_update"),
  setUploadedDate: set("uploaded_date"),
  setBalance: set('balance'),
  setSetOpenDraws: set('open_draws'),
  setOpenMachine: set('open_machine'),
  setWatchDraw: set("watchdraw"),
  setSetOpenLiveDraws:set("open_live_draws"),
  setLiveDrawStatus:set("live_draw_status"),
  setAccount: set("account"),
}
