<template>
    <v-navigation-drawer
      v-model="$store.state.drawer"
      absolute
      dark
      class="hidden-print-only body-1"
      id="va-drawer"
      color="secondary"
    >
      <v-list-item>
        <v-list-item-avatar>
          <v-icon>mdi-times</v-icon>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title><v-img :src="$store.state.applogo" width="70px"></v-img></v-list-item-title>
        </v-list-item-content>
        <v-list-item-action>
           <v-icon @click="setDrawer(false)" style="margin-top: -50px;">mdi-close</v-icon>
        </v-list-item-action>
      </v-list-item>
      <v-divider></v-divider>
      
      <v-list dense>  
      <v-list-item-group color="fff">
          <v-list-item
          v-for="(item, i) in MENUS"
          :key="i + '-main'"
          @click="$router.push(item.page).catch(err => {})"
        >
          <v-list-item-icon>
            <v-icon v-text="item.icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="item.title"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        
      </v-list-item-group>
    </v-list>


    </v-navigation-drawer>

</template>
<script>
import {
  mapMutations
} from 'vuex'
  export default {
    data () {
      return {
        item: 1,
        mainmenu: [
          { title: 'MAIN', icon: 'mdi-view-dashboard', page:'/dashboard'},
         // { title: "TICKETS", icon: 'mdi-ticket', page:'/sales'},
         // { title: "TRANSACTIONS", icon: 'mdi-clipboard-list', page:'/reports'},
          { title: "BETS", icon: 'mdi-clipboard-list-outline', page:'/bets'},
           { title: "REPORTS", icon: 'mdi-chart-bar', page:'/reports'},
           { title: "WALLET", icon: 'mdi-wallet', page:'/wallet'},
           { title: "COMMISSION", icon: 'mdi-percent', page:'/commissions'},
          { title: "MEMBERS", icon: 'mdi-account-multiple', page:'/all-accounts/agents'},
          { title: "CASHOUTS", icon: 'mdi-account-cash', page:'/cashouts'},
          { title: "MY ACCOUNT", icon: 'mdi-account', page:'/account'},
        ],
        playermenu: [
          { title: 'MAIN', icon: 'mdi-view-dashboard', page:'/dashboard'},
          { title: "TICKETS", icon: 'mdi-ticket', page:'/sales'},
          { title: "TRANSACTIONS", icon: 'mdi-clipboard-list-outline', page:'/reports'},
         // { title: "Commission Logs", icon: 'mdi-sack-percent', page:'/commissions'},
          //{ title: "WINNINGS", icon: 'mdi-ticket', page:'/claim'},
          { title: "MY ACCOUNT", icon: 'mdi-account', page:'/account'},
        ],
        mini: true,
      }
    },
    computed: {
      MENUS() {
        return localStorage.user_role != "Player" ?  this.mainmenu : this.playermenu
       
      }
    },
    methods: {
      ...mapMutations(['setDrawer']),
    }
  }
</script>