<template>

  <v-dialog
  tile
    v-model="show"
    persistent
    fullscreen>
    <v-card tile>
        <v-card-text class="pa-4 subtitle-1">
           <div class="text-h4">How to play?</div>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn   color="success"  rounded  @click="emitToParent('ok')">OK</v-btn>
        </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    show: {
        type: Boolean,
        default: false
    }
  },
  methods: {
    emitToParent (action) {
      this.$emit('DialogEvent', action)
    }
  }
}
</script>
